@import "assets/styles/_roboto.css";
@import "assets/styles/_material_icon.css";
@tailwind utilities;
.max-width-500 {
  max-width: 500px;
}

.max-width-600 {
  max-width: 600px;
}

.email-link {
  color: black;
  text-decoration: none;
}

.link {
  color: black;
  text-decoration: none;
}

.container {
  background-color: white;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;
}

.vertical-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.btn-container button:not(:last-child) {
  margin-right: 5px;
}

.option-button-hover .mdc-list-item__primary-text {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.option-button-hover button {
  display: none !important;
}

.option-button-hover:hover button {
  display: inline !important;
}

/* stylelint-disable-next-line scss/no-global-function-names */
.mat-button-loading .mdc-button__label,
.mat-button-loading .mat-icon {
  visibility: hidden;
}

.mat-button-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.mat-column-resize-table.cdk-column-resize-with-resized-column {
  table-layout: fixed;
}

.mat-column-resize-flex .mat-mdc-header-cell,
.mat-column-resize-flex .mat-mdc-cell {
  box-sizing: border-box;
  min-width: 32px;
}

.mat-mdc-header-cell {
  position: relative;
}

.mat-resizable {
  box-sizing: border-box;
}

.mat-mdc-header-cell:not(.mat-resizable)::after,
.mat-resizable-handle {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 1px;
  background: transparent;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
}

.mat-mdc-header-cell:not(.mat-resizable)::after {
  content: "";
}

[dir=rtl] .mat-mdc-header-cell:not(.mat-resizable)::after,
[dir=rtl] .mat-resizable-handle {
  left: 0;
  right: auto;
}

[dir=rtl] .mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-header-cell,
[dir=rtl] .mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell {
  border-left: none;
}

.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell {
  border-right: none;
}

.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell:not(.mat-resizable)::after {
  background-color: var(--mtx-grid-resizable-handle-disabled-background-color, var(--mat-app-outline-variant));
}

.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle {
  background-color: var(--mtx-grid-resizable-handle-hover-background-color, var(--mat-app-primary));
}

.mat-resizable.cdk-resizable-overlay-thumb-active > .mat-resizable-handle {
  opacity: 0;
  transition: none;
}

.mat-resizable-handle:focus,
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle:focus {
  outline: none;
  background-color: var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary));
}

.mat-column-resize-overlay-thumb {
  background: transparent;
  cursor: col-resize;
  width: 100%;
  height: 100%;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-user-select: none;
  user-select: none;
}

.mat-column-resize-overlay-thumb:active {
  will-change: transform;
  background: linear-gradient(90deg, transparent, transparent 7px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 7px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 9px, transparent 9px, transparent);
}

.mat-column-resize-overlay-thumb:active .mat-column-resize-overlay-thumb-top {
  background: linear-gradient(90deg, transparent, transparent 4px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 4px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 12px, transparent 12px, transparent);
}

.mat-column-resize-overlay-thumb-top {
  width: 100%;
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #2e8fce;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #f59e22;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #d13737;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #2e8fce;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2e8fce;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #f59e22;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f59e22;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #f59e22;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f59e22;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #d13737;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #d13737;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2e8fce;
  --mdc-linear-progress-track-color: rgba(46, 143, 206, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f59e22;
  --mdc-linear-progress-track-color: rgba(245, 158, 34, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #d13737;
  --mdc-linear-progress-track-color: rgba(209, 55, 55, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #2e8fce;
  --mdc-filled-text-field-focus-active-indicator-color: #2e8fce;
  --mdc-filled-text-field-focus-label-text-color: rgba(46, 143, 206, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #d13737;
  --mdc-filled-text-field-error-focus-label-text-color: #d13737;
  --mdc-filled-text-field-error-label-text-color: #d13737;
  --mdc-filled-text-field-error-caret-color: #d13737;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #d13737;
  --mdc-filled-text-field-error-focus-active-indicator-color: #d13737;
  --mdc-filled-text-field-error-hover-active-indicator-color: #d13737;
  --mdc-outlined-text-field-caret-color: #2e8fce;
  --mdc-outlined-text-field-focus-outline-color: #2e8fce;
  --mdc-outlined-text-field-focus-label-text-color: rgba(46, 143, 206, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #d13737;
  --mdc-outlined-text-field-error-focus-label-text-color: #d13737;
  --mdc-outlined-text-field-error-label-text-color: #d13737;
  --mdc-outlined-text-field-error-hover-label-text-color: #d13737;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #d13737;
  --mdc-outlined-text-field-error-hover-outline-color: #d13737;
  --mdc-outlined-text-field-error-outline-color: #d13737;
  --mat-form-field-focus-select-arrow-color: rgba(46, 143, 206, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #d13737;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f59e22;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e22;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 34, 0.87);
  --mdc-outlined-text-field-caret-color: #f59e22;
  --mdc-outlined-text-field-focus-outline-color: #f59e22;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 34, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(245, 158, 34, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #d13737;
  --mdc-filled-text-field-focus-active-indicator-color: #d13737;
  --mdc-filled-text-field-focus-label-text-color: rgba(209, 55, 55, 0.87);
  --mdc-outlined-text-field-caret-color: #d13737;
  --mdc-outlined-text-field-focus-outline-color: #d13737;
  --mdc-outlined-text-field-focus-label-text-color: rgba(209, 55, 55, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(209, 55, 55, 0.87);
}

html {
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 14px;
  --mat-form-field-filled-with-label-container-padding-top: 22px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(46, 143, 206, 0.87);
  --mat-select-invalid-arrow-color: rgba(209, 55, 55, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 34, 0.87);
  --mat-select-invalid-arrow-color: rgba(209, 55, 55, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(209, 55, 55, 0.87);
  --mat-select-invalid-arrow-color: rgba(209, 55, 55, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #2e8fce;
  --mdc-chip-elevated-selected-container-color: #2e8fce;
  --mdc-chip-elevated-disabled-container-color: #2e8fce;
  --mdc-chip-flat-disabled-selected-container-color: #2e8fce;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f59e22;
  --mdc-chip-elevated-selected-container-color: #f59e22;
  --mdc-chip-elevated-disabled-container-color: #f59e22;
  --mdc-chip-flat-disabled-selected-container-color: #f59e22;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #d13737;
  --mdc-chip-elevated-selected-container-color: #d13737;
  --mdc-chip-elevated-disabled-container-color: #d13737;
  --mdc-chip-flat-disabled-selected-container-color: #d13737;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 28px;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #2987c9;
  --mdc-switch-selected-handle-color: #2987c9;
  --mdc-switch-selected-hover-state-layer-color: #2987c9;
  --mdc-switch-selected-pressed-state-layer-color: #2987c9;
  --mdc-switch-selected-focus-handle-color: #1260b0;
  --mdc-switch-selected-hover-handle-color: #1260b0;
  --mdc-switch-selected-pressed-handle-color: #1260b0;
  --mdc-switch-selected-focus-track-color: #6db1dd;
  --mdc-switch-selected-hover-track-color: #6db1dd;
  --mdc-switch-selected-pressed-track-color: #6db1dd;
  --mdc-switch-selected-track-color: #6db1dd;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #f4961e;
  --mdc-switch-selected-handle-color: #f4961e;
  --mdc-switch-selected-hover-state-layer-color: #f4961e;
  --mdc-switch-selected-pressed-state-layer-color: #f4961e;
  --mdc-switch-selected-focus-handle-color: #ee700c;
  --mdc-switch-selected-hover-handle-color: #ee700c;
  --mdc-switch-selected-pressed-handle-color: #ee700c;
  --mdc-switch-selected-focus-track-color: #f8bb64;
  --mdc-switch-selected-hover-track-color: #f8bb64;
  --mdc-switch-selected-pressed-track-color: #f8bb64;
  --mdc-switch-selected-track-color: #f8bb64;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #cc3131;
  --mdc-switch-selected-handle-color: #cc3131;
  --mdc-switch-selected-hover-state-layer-color: #cc3131;
  --mdc-switch-selected-pressed-state-layer-color: #cc3131;
  --mdc-switch-selected-focus-handle-color: #b51616;
  --mdc-switch-selected-hover-handle-color: #b51616;
  --mdc-switch-selected-pressed-handle-color: #b51616;
  --mdc-switch-selected-focus-track-color: #df7373;
  --mdc-switch-selected-hover-track-color: #df7373;
  --mdc-switch-selected-pressed-track-color: #df7373;
  --mdc-switch-selected-track-color: #df7373;
}

html {
  --mdc-switch-state-layer-size: 36px;
}
html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2e8fce;
  --mdc-radio-selected-hover-icon-color: #2e8fce;
  --mdc-radio-selected-icon-color: #2e8fce;
  --mdc-radio-selected-pressed-icon-color: #2e8fce;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #2e8fce;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e22;
  --mdc-radio-selected-hover-icon-color: #f59e22;
  --mdc-radio-selected-icon-color: #f59e22;
  --mdc-radio-selected-pressed-icon-color: #f59e22;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f59e22;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d13737;
  --mdc-radio-selected-hover-icon-color: #d13737;
  --mdc-radio-selected-icon-color: #d13737;
  --mdc-radio-selected-pressed-icon-color: #d13737;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #d13737;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 36px;
  --mat-radio-touch-target-display: block;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #2e8fce;
  --mdc-slider-focus-handle-color: #2e8fce;
  --mdc-slider-hover-handle-color: #2e8fce;
  --mdc-slider-active-track-color: #2e8fce;
  --mdc-slider-inactive-track-color: #2e8fce;
  --mdc-slider-with-tick-marks-inactive-container-color: #2e8fce;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #2e8fce;
  --mat-slider-hover-state-layer-color: rgba(46, 143, 206, 0.05);
  --mat-slider-focus-state-layer-color: rgba(46, 143, 206, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #f59e22;
  --mdc-slider-focus-handle-color: #f59e22;
  --mdc-slider-hover-handle-color: #f59e22;
  --mdc-slider-active-track-color: #f59e22;
  --mdc-slider-inactive-track-color: #f59e22;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e22;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #f59e22;
  --mat-slider-hover-state-layer-color: rgba(245, 158, 34, 0.05);
  --mat-slider-focus-state-layer-color: rgba(245, 158, 34, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #d13737;
  --mdc-slider-focus-handle-color: #d13737;
  --mdc-slider-hover-handle-color: #d13737;
  --mdc-slider-active-track-color: #d13737;
  --mdc-slider-inactive-track-color: #d13737;
  --mdc-slider-with-tick-marks-inactive-container-color: #d13737;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #d13737;
  --mat-slider-hover-state-layer-color: rgba(209, 55, 55, 0.05);
  --mat-slider-focus-state-layer-color: rgba(209, 55, 55, 0.2);
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2e8fce;
  --mdc-radio-selected-hover-icon-color: #2e8fce;
  --mdc-radio-selected-icon-color: #2e8fce;
  --mdc-radio-selected-pressed-icon-color: #2e8fce;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e22;
  --mdc-radio-selected-hover-icon-color: #f59e22;
  --mdc-radio-selected-icon-color: #f59e22;
  --mdc-radio-selected-pressed-icon-color: #f59e22;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d13737;
  --mdc-radio-selected-hover-icon-color: #d13737;
  --mdc-radio-selected-icon-color: #d13737;
  --mdc-radio-selected-pressed-icon-color: #d13737;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2e8fce;
  --mdc-checkbox-selected-hover-icon-color: #2e8fce;
  --mdc-checkbox-selected-icon-color: #2e8fce;
  --mdc-checkbox-selected-pressed-icon-color: #2e8fce;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2e8fce;
  --mdc-checkbox-selected-hover-state-layer-color: #2e8fce;
  --mdc-checkbox-selected-pressed-state-layer-color: #2e8fce;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f59e22;
  --mdc-checkbox-selected-hover-icon-color: #f59e22;
  --mdc-checkbox-selected-icon-color: #f59e22;
  --mdc-checkbox-selected-pressed-icon-color: #f59e22;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e22;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e22;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e22;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d13737;
  --mdc-checkbox-selected-hover-icon-color: #d13737;
  --mdc-checkbox-selected-icon-color: #d13737;
  --mdc-checkbox-selected-pressed-icon-color: #d13737;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d13737;
  --mdc-checkbox-selected-hover-state-layer-color: #d13737;
  --mdc-checkbox-selected-pressed-state-layer-color: #d13737;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #2e8fce;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 44px;
  --mdc-list-list-item-two-line-container-height: 60px;
  --mdc-list-list-item-three-line-container-height: 84px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 36px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 52px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 68px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 52px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #2e8fce;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #2e8fce;
  --mat-tab-header-active-ripple-color: #2e8fce;
  --mat-tab-header-inactive-ripple-color: #2e8fce;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #2e8fce;
  --mat-tab-header-active-hover-label-text-color: #2e8fce;
  --mat-tab-header-active-focus-indicator-color: #2e8fce;
  --mat-tab-header-active-hover-indicator-color: #2e8fce;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f59e22;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f59e22;
  --mat-tab-header-active-ripple-color: #f59e22;
  --mat-tab-header-inactive-ripple-color: #f59e22;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e22;
  --mat-tab-header-active-hover-label-text-color: #f59e22;
  --mat-tab-header-active-focus-indicator-color: #f59e22;
  --mat-tab-header-active-hover-indicator-color: #f59e22;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #d13737;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #d13737;
  --mat-tab-header-active-ripple-color: #d13737;
  --mat-tab-header-inactive-ripple-color: #d13737;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #d13737;
  --mat-tab-header-active-hover-label-text-color: #d13737;
  --mat-tab-header-active-focus-indicator-color: #d13737;
  --mat-tab-header-active-hover-indicator-color: #d13737;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #2e8fce;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f59e22;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #d13737;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 44px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f59e22;
  --mdc-checkbox-selected-hover-icon-color: #f59e22;
  --mdc-checkbox-selected-icon-color: #f59e22;
  --mdc-checkbox-selected-pressed-icon-color: #f59e22;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e22;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e22;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e22;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2e8fce;
  --mdc-checkbox-selected-hover-icon-color: #2e8fce;
  --mdc-checkbox-selected-icon-color: #2e8fce;
  --mdc-checkbox-selected-pressed-icon-color: #2e8fce;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2e8fce;
  --mdc-checkbox-selected-hover-state-layer-color: #2e8fce;
  --mdc-checkbox-selected-pressed-state-layer-color: #2e8fce;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d13737;
  --mdc-checkbox-selected-hover-icon-color: #d13737;
  --mdc-checkbox-selected-icon-color: #d13737;
  --mdc-checkbox-selected-pressed-icon-color: #d13737;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d13737;
  --mdc-checkbox-selected-hover-state-layer-color: #d13737;
  --mdc-checkbox-selected-pressed-state-layer-color: #d13737;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 36px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2e8fce;
  --mat-text-button-state-layer-color: #2e8fce;
  --mat-text-button-ripple-color: rgba(46, 143, 206, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f59e22;
  --mat-text-button-state-layer-color: #f59e22;
  --mat-text-button-ripple-color: rgba(245, 158, 34, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #d13737;
  --mat-text-button-state-layer-color: #d13737;
  --mat-text-button-ripple-color: rgba(209, 55, 55, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2e8fce;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f59e22;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #d13737;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2e8fce;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f59e22;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #d13737;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2e8fce;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #2e8fce;
  --mat-outlined-button-ripple-color: rgba(46, 143, 206, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f59e22;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f59e22;
  --mat-outlined-button-ripple-color: rgba(245, 158, 34, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #d13737;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #d13737;
  --mat-outlined-button-ripple-color: rgba(209, 55, 55, 0.1);
}

html {
  --mdc-text-button-container-height: 32px;
  --mdc-filled-button-container-height: 32px;
  --mdc-protected-button-container-height: 32px;
  --mdc-outlined-button-container-height: 32px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #2e8fce;
  --mat-icon-button-state-layer-color: #2e8fce;
  --mat-icon-button-ripple-color: rgba(46, 143, 206, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f59e22;
  --mat-icon-button-state-layer-color: #f59e22;
  --mat-icon-button-ripple-color: rgba(245, 158, 34, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #d13737;
  --mat-icon-button-state-layer-color: #d13737;
  --mat-icon-button-ripple-color: rgba(209, 55, 55, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 44px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 10px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #2e8fce;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #f59e22;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #d13737;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #2e8fce;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #f59e22;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #d13737;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #f59e22;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 52px;
  --mat-table-footer-container-height: 48px;
  --mat-table-row-item-container-height: 48px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #2e8fce;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #f59e22;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #d13737;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #2e8fce;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #f59e22;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #d13737;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 44px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #2e8fce;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(46, 143, 206, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(46, 143, 206, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(46, 143, 206, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #2e8fce;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(46, 143, 206, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e22;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 34, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 34, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 34, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 34, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #d13737;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(209, 55, 55, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(209, 55, 55, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(209, 55, 55, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(209, 55, 55, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f59e22;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #d13737;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 44px;
  --mat-expansion-header-expanded-state-height: 60px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #2e8fce;
}
.mat-icon.mat-accent {
  --mat-icon-color: #f59e22;
}
.mat-icon.mat-warn {
  --mat-icon-color: #d13737;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #2e8fce;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #2e8fce;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #2e8fce;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #d13737;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #d13737;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f59e22;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f59e22;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f59e22;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #d13737;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #d13737;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #d13737;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 68px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #2e8fce;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f59e22;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #d13737;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 60px;
  --mat-toolbar-mobile-height: 52px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 44px;
}

html {
  --mtx-alert-container-shape: 4px;
}

html {
  --mtx-alert-outline-color: transparent;
}
html {
  --mtx-alert-background-color: whitesmoke;
}
html {
  --mtx-alert-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-alert-info-outline-color: transparent;
}
html {
  --mtx-alert-info-background-color: #bbdefb;
}
html {
  --mtx-alert-info-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-alert-success-outline-color: transparent;
}
html {
  --mtx-alert-success-background-color: #c8e6c9;
}
html {
  --mtx-alert-success-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-alert-warning-outline-color: transparent;
}
html {
  --mtx-alert-warning-background-color: #ffecb3;
}
html {
  --mtx-alert-warning-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-alert-danger-outline-color: transparent;
}
html {
  --mtx-alert-danger-background-color: #ffcdd2;
}
html {
  --mtx-alert-danger-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mtx-colorpicker-toggle-active-state-icon-color: #2e8fce;
}
html {
  --mtx-colorpicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
}

.mtx-colorpicker-toggle-active.mat-accent {
  --mtx-colorpicker-toggle-active-state-icon-color: #f59e22;
}
.mtx-colorpicker-toggle-active.mat-warn {
  --mtx-colorpicker-toggle-active-state-icon-color: #d13737;
}

html {
  --mtx-datetimepicker-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-datetimepicker-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-datetimepicker-container-shape: 4px;
}
html {
  --mtx-datetimepicker-container-touch-shape: 4px;
}
html {
  --mtx-datetimepicker-selector-container-shape: 4px;
}

html {
  --mtx-datetimepicker-calendar-header-background-color: #2e8fce;
}
html {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: white;
}
html {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #2e8fce;
}
html {
  --mtx-datetimepicker-calendar-date-selected-disabled-state-background-color: rgba(46, 143, 206, 0.4);
}
html {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: white;
}
html {
  --mtx-datetimepicker-calendar-date-focus-state-background-color: rgba(46, 143, 206, 0.3);
}
html {
  --mtx-datetimepicker-calendar-date-hover-state-background-color: rgba(46, 143, 206, 0.3);
}
html {
  --mtx-datetimepicker-clock-hand-background-color: #2e8fce;
}
html {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #2e8fce;
}
html {
  --mtx-datetimepicker-time-input-active-state-text-color: #2e8fce;
}
html {
  --mtx-datetimepicker-time-input-active-state-background-color: rgba(46, 143, 206, 0.2);
}
html {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #2e8fce;
}
html {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: rgba(46, 143, 206, 0.6);
}
html {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: rgba(46, 143, 206, 0.2);
}
html {
  --mtx-datetimepicker-toggle-active-state-icon-color: #2e8fce;
}
html {
  --mtx-datetimepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
}
html {
  --mtx-datetimepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
}
html {
  --mtx-datetimepicker-calendar-header-text-color: white;
}
html {
  --mtx-datetimepicker-calendar-header-divider-color: transparent;
}
html {
  --mtx-datetimepicker-calendar-table-header-text-color: rgba(0, 0, 0, 0.54);
}
html {
  --mtx-datetimepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
}
html {
  --mtx-datetimepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-datetimepicker-calendar-date-outline-color: transparent;
}
html {
  --mtx-datetimepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mtx-datetimepicker-container-background-color: white;
}
html {
  --mtx-datetimepicker-container-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-datetimepicker-clock-dial-background-color: rgba(0, 0, 0, 0.12);
}
html {
  --mtx-datetimepicker-clock-cell-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-datetimepicker-clock-cell-hover-state-background-color: rgba(0, 0, 0, 0.04);
}
html {
  --mtx-datetimepicker-clock-cell-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mtx-datetimepicker-time-input-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-datetimepicker-time-input-background-color: rgba(0, 0, 0, 0.12);
}
html {
  --mtx-datetimepicker-time-input-focus-state-background-color: #fafafa;
}
html {
  --mtx-datetimepicker-time-input-warn-state-outline-color: #d13737;
}
html {
  --mtx-datetimepicker-time-ampm-text-color: rgba(0, 0, 0, 0.75);
}
html {
  --mtx-datetimepicker-time-ampm-outline-color: rgba(0, 0, 0, 0.38);
}
html {
  --mtx-datetimepicker-time-ampm-selected-state-text-color: rgba(0, 0, 0, 0.87);
}

.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-calendar-header-background-color: #f59e22;
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: white;
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #f59e22;
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 34, 0.4);
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: white;
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-calendar-date-focus-state-background-color: rgba(245, 158, 34, 0.3);
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-calendar-date-hover-state-background-color: rgba(245, 158, 34, 0.3);
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-clock-hand-background-color: #f59e22;
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #f59e22;
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-time-input-active-state-text-color: #f59e22;
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-time-input-active-state-background-color: rgba(245, 158, 34, 0.2);
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #f59e22;
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: rgba(245, 158, 34, 0.6);
}
.mtx-datetimepicker-content.mat-accent {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: rgba(245, 158, 34, 0.2);
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-calendar-header-background-color: #d13737;
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: white;
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #d13737;
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-calendar-date-selected-disabled-state-background-color: rgba(209, 55, 55, 0.4);
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: white;
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-calendar-date-focus-state-background-color: rgba(209, 55, 55, 0.3);
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-calendar-date-hover-state-background-color: rgba(209, 55, 55, 0.3);
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-clock-hand-background-color: #d13737;
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #d13737;
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-time-input-active-state-text-color: #d13737;
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-time-input-active-state-background-color: rgba(209, 55, 55, 0.2);
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #d13737;
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: rgba(209, 55, 55, 0.6);
}
.mtx-datetimepicker-content.mat-warn {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: rgba(209, 55, 55, 0.2);
}

.mtx-datetimepicker-toggle-active.mat-accent {
  --mtx-datetimepicker-toggle-active-state-icon-color: #f59e22;
}
.mtx-datetimepicker-toggle-active.mat-warn {
  --mtx-datetimepicker-toggle-active-state-icon-color: #d13737;
}

html {
  --mtx-drawer-container-shape: 0;
}
html {
  --mtx-drawer-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

html {
  --mtx-drawer-container-background-color: white;
}
html {
  --mtx-drawer-container-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mtx-grid-container-shape: 0;
}
html {
  --mtx-grid-table-cell-min-width: 80px;
}

html {
  --mtx-grid-outline-color: rgba(0, 0, 0, 0.2);
}
html {
  --mtx-grid-column-menu-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-grid-column-menu-divider-color: rgba(0, 0, 0, 0.12);
}
html {
  --mtx-grid-table-footer-background-color: whitesmoke;
}
html {
  --mtx-grid-table-row-striped-background-color: #f5f5f5;
}
html {
  --mtx-grid-table-row-hover-background-color: #eee;
}
html {
  --mtx-grid-table-row-selected-background-color: #eee;
}
html {
  --mtx-grid-table-row-selected-hover-background-color: #e0e0e0;
}
html {
  --mtx-grid-table-cell-selected-outline-color: #f59e22;
}
html {
  --mtx-grid-resizable-handle-active-background-color: #2e8fce;
}
html {
  --mtx-grid-resizable-handle-hover-background-color: #2e8fce;
}
html {
  --mtx-grid-resizable-handle-disabled-background-color: rgba(0, 0, 0, 0.12);
}

html {
  --mtx-grid-row-expand-button-size: 44px;
}

html {
  --mtx-loader-backdrop-background-color: rgba(250, 250, 250, 0.75);
}

html {
  --mtx-popover-container-shape: 4px;
}
html {
  --mtx-popover-base-elevation-level: 8;
}

html {
  --mtx-popover-outline-color: rgba(0, 0, 0, 0.12);
}
html {
  --mtx-popover-background-color: white;
}
html {
  --mtx-popover-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mtx-progress-container-shape: 4px;
}

html {
  --mtx-progress-track-color: rgba(0, 0, 0, 0.04);
}
html {
  --mtx-progress-indicator-color: rgba(0, 0, 0, 0.12);
}
html {
  --mtx-progress-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-progress-info-indicator-color: #2196f3;
}
html {
  --mtx-progress-info-text-color: white;
}
html {
  --mtx-progress-success-indicator-color: #4caf50;
}
html {
  --mtx-progress-success-text-color: white;
}
html {
  --mtx-progress-warning-indicator-color: #ff9800;
}
html {
  --mtx-progress-warning-text-color: white;
}
html {
  --mtx-progress-danger-indicator-color: #f44336;
}
html {
  --mtx-progress-danger-text-color: white;
}

html {
  --mtx-select-container-shape: 4px;
}
html {
  --mtx-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mtx-select-option-selected-state-text-color: #2e8fce;
}
html {
  --mtx-select-container-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-select-placeholder-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mtx-select-disabled-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mtx-select-multiple-value-background-color: #e0e0e0;
}
html {
  --mtx-select-multiple-value-outline-color: rgba(0, 0, 0, 0.12);
}
html {
  --mtx-select-multiple-value-icon-hover-background-color: rgba(0, 0, 0, 0.12);
}
html {
  --mtx-select-clear-icon-color: rgba(0, 0, 0, 0.54);
}
html {
  --mtx-select-clear-icon-hover-color: #d13737;
}
html {
  --mtx-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
}
html {
  --mtx-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
}
html {
  --mtx-select-invalid-arrow-color: #d13737;
}
html {
  --mtx-select-panel-background-color: white;
}
html {
  --mtx-select-panel-divider-color: rgba(0, 0, 0, 0.12);
}
html {
  --mtx-select-optgroup-label-text-color: rgba(0, 0, 0, 0.54);
}
html {
  --mtx-select-option-label-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mtx-select-option-selected-state-background-color: rgba(0, 0, 0, 0.04);
}
html {
  --mtx-select-option-hover-state-background-color: rgba(0, 0, 0, 0.04);
}
html {
  --mtx-select-option-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.ng-dropdown-panel.mat-accent {
  --mtx-select-option-selected-state-text-color: #f59e22;
}
.ng-dropdown-panel.mat-warn {
  --mtx-select-option-selected-state-text-color: #d13737;
}

html {
  --mtx-split-gutter-hover-state-background-color: #2e8fce;
}
html {
  --mtx-split-gutter-background-color: rgba(0, 0, 0, 0.12);
}

.mtx-split > .mtx-split-gutter.mat-accent:hover {
  --mtx-split-gutter-hover-state-background-color: #f59e22;
}
.mtx-split > .mtx-split-gutter.mat-warn:hover {
  --mtx-split-gutter-hover-state-background-color: #d13737;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
}
html {
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
}
html {
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 44px !important;
  width: 44px !important;
}

td mat-icon,
mat-nested-tree-node mat-icon {
  font-size: 22px !important;
  width: 22px !important;
  height: 22px !important;
  line-height: 22px;
}

html,
body {
  height: 100%;
}

* {
  letter-spacing: normal !important;
}

body {
  background-color: #f8f8f8;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

b {
  font-weight: 500 !important;
}

:root {
  --primary: rgba(61, 147, 206, 0.94);
  --primary-dark: #13527b;
  --success: #188038;
  --success-dark: #105525;
  --accent: #f59e22;
  --accent-dark: #c58508;
  --warn: #d13737;
  --warn-dark: #ad2828;
  --green: #188038;
  --light-gray: #d9d9d9;
  --gray: #a1a1a1;
  --black: #3c4043;
  --disabled-color: rgba(0, 0, 0, 0.9);
  --mdc-outlined-text-field-disabled-label-text-color: var(--disabled-color);
  --mdc-outlined-text-field-disabled-input-text-color: var(--disabled-color);
  --mdc-outlined-text-field-disabled-input: var(--disabled-color);
  --mat-select-disabled-trigger-text-color: var(--disabled-color);
  --mdc-switch-disabled-label-text-color: var(--disabled-color);
}

.mat-mdc-radio-button.mat-accent {
  --mat-radio-disabled-label-color: var(--disabled-color);
}

.mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.55 !important;
}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  --mat-checkbox-disabled-label-color: var(--disabled-color);
}

p a {
  text-decoration: none;
  color: #1d72bc;
}

.text-primary {
  color: #2e8fce !important;
}

.text-secondary {
  color: #f59e22 !important;
}

.text-success {
  color: #188038 !important;
}

.text-gold {
  color: gold;
}

.text-warning {
  color: #d13737 !important;
}

.text-light-gray {
  color: var(--light-gray);
}

.text-gray {
  color: var(--gray) !important;
}

.text-black {
  color: var(--black);
}

.background-primary {
  background-color: #2e8fce !important;
}

.background-accent {
  background-color: #f59e22 !important;
}

.background-warning {
  background-color: #d13737 !important;
}

.background-success {
  background-color: #188038 !important;
}

.snackbar-primary {
  background: #2e8fce !important;
  color: rgb(255, 255, 255);
}

.snackbar-accent {
  background: #f59e22 !important;
  color: rgb(255, 255, 255);
}

.snackbar-accent {
  background: var(--accent) !important;
  color: rgb(255, 255, 255);
}
.snackbar-accent .mdc-snackbar__surface {
  background: unset !important;
}

.snackbar-warning {
  background: var(--warn) !important;
  color: rgb(255, 255, 255) !important;
}
.snackbar-warning .mat-simple-snackbar-action {
  color: antiquewhite;
}
.snackbar-warning .mdc-snackbar__surface {
  background: unset !important;
}

.snackbar-success {
  background: var(--success) !important;
  color: rgb(255, 255, 255);
}
.snackbar-success .mdc-snackbar__surface {
  background: unset !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  background-color: rgba(25, 106, 159, 0.08) !important;
}

.mat-chip.mat-standard-chip.mat-chip-warn {
  background-color: #d13737 !important;
  color: white !important;
  fill: white !important;
}

.sidenav-container {
  top: 60px !important;
}

@media (max-width: 599px) {
  .sidenav-container {
    top: 52px !important;
  }
}
/* compressed table */
.compressed-table {
  overflow: hidden;
}
.compressed-table tr.mat-mdc-row,
.compressed-table tr.mat-mdc-header-row {
  height: 30px !important;
}
.compressed-table .mat-icon {
  scale: 0.75;
}
.compressed-table .mdc-checkbox {
  padding: unset;
}
.compressed-table .mdc-checkbox .mdc-checkbox__background {
  top: unset;
  left: unset;
}
.compressed-table .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 30px;
}
.compressed-table .mat-mdc-icon-button {
  padding: 3px !important;
}
.compressed-table .mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 30px !important;
  width: 30px !important;
}
.compressed-table .mat-mdc-paginator-container {
  min-height: 30px !important;
}
.compressed-table .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 1px;
  padding-bottom: 1px;
}
.compressed-table .mat-mdc-text-field-wrapper {
  height: 27px;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%, 100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}
.color--primary.mat-icon {
  color: #13527b !important;
}
.color--primary.mat-mdc-chip {
  background-color: #13527b !important;
  color: white;
}
.color--primary.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--primary.text {
  color: #13527b !important;
}
.color--primary.background {
  background-color: #13527b !important;
}
.color--primary.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #13527b;
  transition: 0.2s;
  background-image: linear-gradient(#13527b, #13527b) !important;
  color: white;
}
.color--primary.striped {
  background: repeating-linear-gradient(45deg, #13527b 0%, #13527b 10%, #1a6fa7 10%, #1a6fa7 20%) !important;
  color: white;
}
.color--warning.mat-icon {
  color: #ad2828 !important;
}
.color--warning.mat-mdc-chip {
  background-color: #ad2828 !important;
  color: white;
}
.color--warning.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--warning.text {
  color: #ad2828 !important;
}
.color--warning.background {
  background-color: #ad2828 !important;
}
.color--warning.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #ad2828;
  transition: 0.2s;
  background-image: linear-gradient(#ad2828, #ad2828) !important;
  color: white;
}
.color--warning.striped {
  background: repeating-linear-gradient(45deg, #ad2828 0%, #ad2828 10%, #d13737 10%, #d13737 20%) !important;
  color: white;
}
.color--accent.mat-icon {
  color: #c58508 !important;
}
.color--accent.mat-mdc-chip {
  background-color: #c58508 !important;
  color: white;
}
.color--accent.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--accent.text {
  color: #c58508 !important;
}
.color--accent.background {
  background-color: #c58508 !important;
}
.color--accent.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #c58508;
  transition: 0.2s;
  background-image: linear-gradient(#c58508, #c58508) !important;
  color: white;
}
.color--accent.striped {
  background: repeating-linear-gradient(45deg, #c58508 0%, #c58508 10%, #f5a60b 10%, #f5a60b 20%) !important;
  color: white;
}
.color--success.mat-icon {
  color: #105525 !important;
}
.color--success.mat-mdc-chip {
  background-color: #105525 !important;
  color: white;
}
.color--success.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--success.text {
  color: #105525 !important;
}
.color--success.background {
  background-color: #105525 !important;
}
.color--success.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #105525;
  transition: 0.2s;
  background-image: linear-gradient(#105525, #105525) !important;
  color: white;
}
.color--success.striped {
  background: repeating-linear-gradient(45deg, #105525 0%, #105525 10%, #188038 10%, #188038 20%) !important;
  color: white;
}
.color--deep-purple.mat-icon {
  color: #4a148c !important;
}
.color--deep-purple.mat-mdc-chip {
  background-color: #4a148c !important;
  color: white;
}
.color--deep-purple.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--deep-purple.text {
  color: #4a148c !important;
}
.color--deep-purple.background {
  background-color: #4a148c !important;
}
.color--deep-purple.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #4a148c;
  transition: 0.2s;
  background-image: linear-gradient(#4a148c, #4a148c) !important;
  color: white;
}
.color--deep-purple.striped {
  background: repeating-linear-gradient(45deg, #4a148c 0%, #4a148c 10%, #621ab9 10%, #621ab9 20%) !important;
  color: white;
}
.color--purple.mat-icon {
  color: #7986cb !important;
}
.color--purple.mat-mdc-chip {
  background-color: #7986cb !important;
  color: white;
}
.color--purple.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--purple.text {
  color: #7986cb !important;
}
.color--purple.background {
  background-color: #7986cb !important;
}
.color--purple.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #7986cb;
  transition: 0.2s;
  background-image: linear-gradient(#7986cb, #7986cb) !important;
  color: white;
}
.color--purple.striped {
  background: repeating-linear-gradient(45deg, #7986cb 0%, #7986cb 10%, #9ea7d9 10%, #9ea7d9 20%) !important;
  color: white;
}
.color--light-blue.mat-icon {
  color: #80c5d0 !important;
}
.color--light-blue.mat-mdc-chip {
  background-color: #80c5d0 !important;
  color: white;
}
.color--light-blue.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--light-blue.text {
  color: #80c5d0 !important;
}
.color--light-blue.background {
  background-color: #80c5d0 !important;
}
.color--light-blue.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #80c5d0;
  transition: 0.2s;
  background-image: linear-gradient(#80c5d0, #80c5d0) !important;
  color: white;
}
.color--light-blue.striped {
  background: repeating-linear-gradient(45deg, #80c5d0 0%, #80c5d0 10%, #a5d6de 10%, #a5d6de 20%) !important;
  color: white;
}
.color--teal.mat-icon {
  color: #004d40 !important;
}
.color--teal.mat-mdc-chip {
  background-color: #004d40 !important;
  color: white;
}
.color--teal.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--teal.text {
  color: #004d40 !important;
}
.color--teal.background {
  background-color: #004d40 !important;
}
.color--teal.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #004d40;
  transition: 0.2s;
  background-image: linear-gradient(#004d40, #004d40) !important;
  color: white;
}
.color--teal.striped {
  background: repeating-linear-gradient(45deg, #004d40 0%, #004d40 10%, #00806a 10%, #00806a 20%) !important;
  color: white;
}
.color--lime.mat-icon {
  color: #d8ec8f !important;
}
.color--lime.mat-mdc-chip {
  background-color: #d8ec8f !important;
  color: white;
}
.color--lime.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--lime.text {
  color: #d8ec8f !important;
}
.color--lime.background {
  background-color: #d8ec8f !important;
}
.color--lime.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #d8ec8f;
  transition: 0.2s;
  background-image: linear-gradient(#d8ec8f, #d8ec8f) !important;
  color: white;
}
.color--lime.striped {
  background: repeating-linear-gradient(45deg, #d8ec8f 0%, #d8ec8f 10%, #e7f3bb 10%, #e7f3bb 20%) !important;
  color: white;
}
.color--blue.mat-icon {
  color: rgba(61, 147, 206, 0.937254902) !important;
}
.color--blue.mat-mdc-chip {
  background-color: rgba(61, 147, 206, 0.937254902) !important;
  color: white;
}
.color--blue.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--blue.text {
  color: rgba(61, 147, 206, 0.937254902) !important;
}
.color--blue.background {
  background-color: rgba(61, 147, 206, 0.937254902) !important;
}
.color--blue.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: rgba(61, 147, 206, 0.937254902);
  transition: 0.2s;
  background-image: linear-gradient(rgba(61, 147, 206, 0.937254902), rgba(61, 147, 206, 0.937254902)) !important;
  color: white;
}
.color--blue.striped {
  background: repeating-linear-gradient(45deg, rgba(61, 147, 206, 0.937254902) 0%, rgba(61, 147, 206, 0.937254902) 10%, rgba(102, 170, 216, 0.937254902) 10%, rgba(102, 170, 216, 0.937254902) 20%) !important;
  color: white;
}
.color--green.mat-icon {
  color: #188038 !important;
}
.color--green.mat-mdc-chip {
  background-color: #188038 !important;
  color: white;
}
.color--green.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--green.text {
  color: #188038 !important;
}
.color--green.background {
  background-color: #188038 !important;
}
.color--green.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #188038;
  transition: 0.2s;
  background-image: linear-gradient(#188038, #188038) !important;
  color: white;
}
.color--green.striped {
  background: repeating-linear-gradient(45deg, #188038 0%, #188038 10%, #20ab4b 10%, #20ab4b 20%) !important;
  color: white;
}
.color--yellow.mat-icon {
  color: #f6bf26 !important;
}
.color--yellow.mat-mdc-chip {
  background-color: #f6bf26 !important;
  color: white;
}
.color--yellow.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--yellow.text {
  color: #f6bf26 !important;
}
.color--yellow.background {
  background-color: #f6bf26 !important;
}
.color--yellow.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #f6bf26;
  transition: 0.2s;
  background-image: linear-gradient(#f6bf26, #f6bf26) !important;
  color: white;
}
.color--yellow.striped {
  background: repeating-linear-gradient(45deg, #f6bf26 0%, #f6bf26 10%, #f8cd57 10%, #f8cd57 20%) !important;
  color: white;
}
.color--cyan.mat-icon {
  color: #006064 !important;
}
.color--cyan.mat-mdc-chip {
  background-color: #006064 !important;
  color: white;
}
.color--cyan.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--cyan.text {
  color: #006064 !important;
}
.color--cyan.background {
  background-color: #006064 !important;
}
.color--cyan.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #006064;
  transition: 0.2s;
  background-image: linear-gradient(#006064, #006064) !important;
  color: white;
}
.color--cyan.striped {
  background: repeating-linear-gradient(45deg, #006064 0%, #006064 10%, #009197 10%, #009197 20%) !important;
  color: white;
}
.color--orange.mat-icon {
  color: #f59e22 !important;
}
.color--orange.mat-mdc-chip {
  background-color: #f59e22 !important;
  color: white;
}
.color--orange.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--orange.text {
  color: #f59e22 !important;
}
.color--orange.background {
  background-color: #f59e22 !important;
}
.color--orange.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #f59e22;
  transition: 0.2s;
  background-image: linear-gradient(#f59e22, #f59e22) !important;
  color: white;
}
.color--orange.striped {
  background: repeating-linear-gradient(45deg, #f59e22 0%, #f59e22 10%, #f7b353 10%, #f7b353 20%) !important;
  color: white;
}
.color--deep-pink.mat-icon {
  color: #c51162 !important;
}
.color--deep-pink.mat-mdc-chip {
  background-color: #c51162 !important;
  color: white;
}
.color--deep-pink.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--deep-pink.text {
  color: #c51162 !important;
}
.color--deep-pink.background {
  background-color: #c51162 !important;
}
.color--deep-pink.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #c51162;
  transition: 0.2s;
  background-image: linear-gradient(#c51162, #c51162) !important;
  color: white;
}
.color--deep-pink.striped {
  background: repeating-linear-gradient(45deg, #c51162 0%, #c51162 10%, #ec1d7a 10%, #ec1d7a 20%) !important;
  color: white;
}
.color--pink.mat-icon {
  color: #e67c73 !important;
}
.color--pink.mat-mdc-chip {
  background-color: #e67c73 !important;
  color: white;
}
.color--pink.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--pink.text {
  color: #e67c73 !important;
}
.color--pink.background {
  background-color: #e67c73 !important;
}
.color--pink.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #e67c73;
  transition: 0.2s;
  background-image: linear-gradient(#e67c73, #e67c73) !important;
  color: white;
}
.color--pink.striped {
  background: repeating-linear-gradient(45deg, #e67c73 0%, #e67c73 10%, #eea49e 10%, #eea49e 20%) !important;
  color: white;
}
.color--red.mat-icon {
  color: #d13737 !important;
}
.color--red.mat-mdc-chip {
  background-color: #d13737 !important;
  color: white;
}
.color--red.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--red.text {
  color: #d13737 !important;
}
.color--red.background {
  background-color: #d13737 !important;
}
.color--red.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #d13737;
  transition: 0.2s;
  background-image: linear-gradient(#d13737, #d13737) !important;
  color: white;
}
.color--red.striped {
  background: repeating-linear-gradient(45deg, #d13737 0%, #d13737 10%, #db6060 10%, #db6060 20%) !important;
  color: white;
}
.color--blue-gray.mat-icon {
  color: #546e7a !important;
}
.color--blue-gray.mat-mdc-chip {
  background-color: #546e7a !important;
  color: white;
}
.color--blue-gray.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--blue-gray.text {
  color: #546e7a !important;
}
.color--blue-gray.background {
  background-color: #546e7a !important;
}
.color--blue-gray.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #546e7a;
  transition: 0.2s;
  background-image: linear-gradient(#546e7a, #546e7a) !important;
  color: white;
}
.color--blue-gray.striped {
  background: repeating-linear-gradient(45deg, #546e7a 0%, #546e7a 10%, #698998 10%, #698998 20%) !important;
  color: white;
}
.color--brown.mat-icon {
  color: #795548 !important;
}
.color--brown.mat-mdc-chip {
  background-color: #795548 !important;
  color: white;
}
.color--brown.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--brown.text {
  color: #795548 !important;
}
.color--brown.background {
  background-color: #795548 !important;
}
.color--brown.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #795548;
  transition: 0.2s;
  background-image: linear-gradient(#795548, #795548) !important;
  color: white;
}
.color--brown.striped {
  background: repeating-linear-gradient(45deg, #795548 0%, #795548 10%, #996b5b 10%, #996b5b 20%) !important;
  color: white;
}
.color--black.mat-icon {
  color: #212121 !important;
}
.color--black.mat-mdc-chip {
  background-color: #212121 !important;
  color: white;
}
.color--black.mat-mdc-chip .mat-chip-remove {
  color: white;
  opacity: 0.9;
}
.color--black.text {
  color: #212121 !important;
}
.color--black.background {
  background-color: #212121 !important;
}
.color--black.event {
  background-size: 0 100%;
  background-repeat: no-repeat;
  background-color: #212121;
  transition: 0.2s;
  background-image: linear-gradient(#212121, #212121) !important;
  color: white;
}
.color--black.striped {
  background: repeating-linear-gradient(45deg, #212121 0%, #212121 10%, #3b3b3b 10%, #3b3b3b 20%) !important;
  color: white;
}

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 3px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */
.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

.mat-large-icon-button {
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.mat-large-icon-button > *[role=img] {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.mat-large-icon-button > *[role=img] svg {
  width: 24px;
  height: 24px;
}
.mat-large-icon-button .mat-mdc-button-touch-target {
  width: 40px !important;
  height: 40px !important;
}

.mat-medium-icon-button {
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.mat-medium-icon-button > *[role=img] {
  width: 19px;
  height: 19px;
  font-size: 19px;
}
.mat-medium-icon-button > *[role=img] svg {
  width: 19px;
  height: 19px;
}
.mat-medium-icon-button .mat-mdc-button-touch-target {
  width: 32px !important;
  height: 32px !important;
}

.mat-small-icon-button {
  width: 26px !important;
  height: 26px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.mat-small-icon-button > *[role=img] {
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.mat-small-icon-button > *[role=img] svg {
  width: 16px;
  height: 16px;
}
.mat-small-icon-button .mat-mdc-button-touch-target {
  width: 26px !important;
  height: 26px !important;
}

.mat-tiny-icon-button {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.mat-tiny-icon-button > *[role=img] {
  width: 12px;
  height: 12px;
  font-size: 12px;
}
.mat-tiny-icon-button > *[role=img] svg {
  width: 12px;
  height: 12px;
}
.mat-tiny-icon-button .mat-mdc-button-touch-target {
  width: 20px !important;
  height: 20px !important;
}

.mat-large-button {
  height: 50px !important;
  padding: 0 !important;
}
.mat-large-button > *[role=img] {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
}
.mat-large-button > *[role=img] svg {
  width: 24px;
  height: 24px;
}
.mat-large-button .mat-mdc-button-touch-target {
  width: 50px !important;
  height: 50px !important;
}
.mat-large-button > span {
  font-size: 19px !important;
}

.mat-medium-button {
  height: 32px !important;
  padding: 0 !important;
}
.mat-medium-button > *[role=img] {
  width: 19px !important;
  height: 19px !important;
  font-size: 19px !important;
}
.mat-medium-button > *[role=img] svg {
  width: 19px;
  height: 19px;
}
.mat-medium-button .mat-mdc-button-touch-target {
  width: 32px !important;
  height: 32px !important;
}
.mat-medium-button > span {
  font-size: 14px !important;
}

.mat-small-button {
  height: 26px !important;
  padding: 0 !important;
}
.mat-small-button > *[role=img] {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
}
.mat-small-button > *[role=img] svg {
  width: 16px;
  height: 16px;
}
.mat-small-button .mat-mdc-button-touch-target {
  width: 26px !important;
  height: 26px !important;
}
.mat-small-button > span {
  font-size: 11px !important;
}

.mat-tiny-button {
  height: 20px !important;
  padding: 0 !important;
}
.mat-tiny-button > *[role=img] {
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
}
.mat-tiny-button > *[role=img] svg {
  width: 12px;
  height: 12px;
}
.mat-tiny-button .mat-mdc-button-touch-target {
  width: 20px !important;
  height: 20px !important;
}
.mat-tiny-button > span {
  font-size: 7px !important;
}

.mat-loading.mat-mdc-icon-button,
.mat-loading .mat-button-wrapper {
  display: none;
}
.mat-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}
.mat-loading .mat-icon,
.mat-loading .mdc-button__label {
  display: none !important;
}

.logo-toolbar {
  height: 40%;
  margin-right: 5px;
}

.icon-toolbar {
  margin-right: 10px;
}

mat-sidenav-content {
  background-color: white;
}

.btn-save {
  width: 150px;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 20px !important;
}

mat-cell button:not(:last-child) {
  margin-right: 5px;
}

.mat-toolbar-row .mat-button-wrapper span {
  margin-left: 5px;
}

.table-container {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
@media screen and (max-width: 992px) {
  .table-container .mat-table-sticky-border-elem-right {
    box-shadow: -2px 0 0 0 rgba(0, 0, 0, 0.04), inset 1px 0 0 0 rgba(0, 0, 0, 0.12);
  }
  .table-container th.mat-header-cell,
  .table-container td.mat-cell,
  .table-container td.mat-footer-cell {
    padding: 0 5px;
  }
}
.table-container .mat-cell-no-row {
  text-align: center;
  opacity: 0.8;
}

.fix-prefix.mat-form-field-appearance-outline .mat-form-field-prefix {
  top: 0 !important;
}
.fix-prefix.mat-form-field-appearance-outline .mat-form-field-prefix span {
  padding: 0 0.5rem;
  opacity: 0.8;
  font-weight: 500;
}

.fix-suffix.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}
.fix-suffix.mat-form-field-appearance-outline .mat-form-field-suffix span {
  padding: 0 0.5rem;
  opacity: 0.8;
  font-weight: 500;
}

.right-sheet-container {
  background-color: white;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 399px) {
  .right-sheet-container .fix-width {
    max-width: 300px !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 499px) {
  .right-sheet-container .fix-width {
    max-width: 350px !important;
  }
}
@media screen and (min-width: 500px) {
  .right-sheet-container .fix-width th.mat-header-cell,
  .right-sheet-container .fix-width td.mat-cell,
  .right-sheet-container .fix-width td.mat-footer-cell {
    padding: 0 5px;
  }
}

.empty-cell {
  opacity: 0.7;
}

.mat-checkbox-layout {
  white-space: unset !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
}

/** Dialog Content **/
.dialog-content {
  overflow: unset !important;
}
.dialog-content.width-500 {
  width: 500px !important;
}
.dialog-content.width-600 {
  width: 600px !important;
}
.dialog-content mat-radio-button p,
.dialog-content mat-radio-button span {
  white-space: initial !important;
}
.dialog-content mat-radio-button p {
  margin: 0;
}
.dialog-content mat-radio-button mat-hint {
  font-size: 13px;
  white-space: initial;
}

@media screen and (max-width: 600px) {
  .dialog-content {
    overflow: unset !important;
  }
  .dialog-content.width-500, .dialog-content.width-600 {
    max-width: 100% !important;
  }
}
.notifications-panel {
  position: absolute !important;
  top: 50px;
  right: 100px;
}
.notifications-panel .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px !important;
}
@media screen and (max-width: 450px) {
  .notifications-panel {
    right: unset !important;
  }
}
.notifications-panel .mat-mdc-dialog-content {
  padding: 0 !important;
  margin: 0 !important;
}
.notifications-panel .mat-mdc-dialog-title {
  padding: 0 !important;
}
.notifications-panel .mat-mdc-dialog-title::before {
  margin: 0 !important;
  content: unset !important;
}

/** CSS per la visualizzazione delle icone di stato */
.status-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 0.7rem;
  width: 0.7rem;
  border: 1px solid white;
  border-radius: 50%;
}

.status-icon.assente {
  background-color: #d9d9d9;
}

.status-icon.remoto {
  background-color: rgb(245, 158, 11);
}

.status-icon.presente {
  background-color: #188038;
}

.no-subscript .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.extend-form-field .mat-mdc-form-field {
  width: 100%;
}

.mtx-datetimepicker-content .mtx-calendar {
  height: unset !important;
}

.textarea {
  min-height: 150px;
}

.mat-mdc-form-field-subscript-wrapper mat-hint {
  margin-top: -3px;
  color: var(--black);
}

.ghost-element .line-form {
  width: 100%;
  height: 56px;
  margin: 0.25em 0 1.34375em 0;
  border-radius: 3px;
  background: rgb(204, 199, 199) linear-gradient(90deg, #f0f0f0, #d8d6d6, #f0f0f0) fixed 0 0;
  animation: ghost 1200ms infinite linear;
}
.ghost-element .flex flex-row .line-form {
  width: 18%;
}

@media (max-width: 1023px) {
  .mat-toolbar-row .mdc-button .mat-icon {
    margin-right: 0 !important;
  }
  .mat-toolbar-row .mdc-button .mdc-button__label {
    display: none;
  }
  .hide-label .mat-icon {
    margin-right: 0 !important;
  }
  .hide-label .mdc-button__label {
    display: none;
  }
}
.flex-10 {
  flex: 1 1 10%;
  min-width: 10%;
  box-sizing: border-box;
}

.flex-15 {
  flex: 1 1 15%;
  min-width: 15%;
  box-sizing: border-box;
}

.flex-30 {
  flex: 1 1 30%;
  min-width: 30%;
  box-sizing: border-box;
}

.flex-55 {
  flex: 1 1 55%;
  min-width: 55%;
  box-sizing: border-box;
}

.flex-80 {
  flex: 1 1 80%;
  min-width: 80%;
  box-sizing: border-box;
}

.divide-y {
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}