$colors: (
  primary: #13527b,
  warning: #ad2828ff,
  accent: #c58508ff,
  success: #105525ff,
  deep-purple: #4a148c,
  purple: #7986cbff,
  light-blue: #80c5d0,
  teal: #004d40,
  lime: #d8ec8fff,
  blue: #3d93ceef,
  green: #188038ff,
  yellow: #f6bf26ff,
  cyan: #006064,
  orange: #f59e22ff,
  deep-pink: #c51162,
  pink: #e67c73ff,
  red: #d13737ff,
  blue-gray: #546e7a,
  brown: #795548,
  black: #212121,
);

.color {
  @each $color, $value in $colors {
    &--#{"" + $color} {
      &.mat-icon {
        color: $value !important;
      }

      &.mat-mdc-chip {
        background-color: $value !important;
        color: white;

        .mat-chip-remove {
          color: white;
          opacity: 0.9;
        }
      }

      &.text {
        color: $value !important;
      }

      &.background {
        background-color: $value !important;
      }

      &.event {
        background-size: 0 100%;
        background-repeat: no-repeat;
        background-color: $value;
        transition: 0.2s;
        background-image: linear-gradient($value, $value) !important;
        color: white;
      }

      &.striped {
        background: repeating-linear-gradient(
          45deg,
          $value 0%,
          $value 10%,
          lighten($value, 10%) 10%,
          lighten($value, 10%) 20%
        ) !important;
        color: white;
      }
    }
  }
}
