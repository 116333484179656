/* roboto-100 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-100.woff2") format("woff2");
}

/* roboto-100italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-100italic.woff2")
    format("woff2");
}

/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-300.woff2") format("woff2");
}

/* roboto-300italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-300italic.woff2")
    format("woff2");
}

/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-regular.woff2")
    format("woff2");
}

/* roboto-italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-italic.woff2")
    format("woff2");
}

/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-500.woff2") format("woff2");
}

/* roboto-500italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-500italic.woff2")
    format("woff2");
}

/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-700.woff2") format("woff2");
}

/* roboto-700italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("/assets/fonts/roboto-v30-latin-ext_latin-700italic.woff2")
    format("woff2");
}
