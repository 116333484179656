// Tailwind
@tailwind utilities;

@import "../node_modules/@corem/common/assets/common";
@import "assets/styles/theming";
@import "assets/styles/_roboto.css";
@import "assets/styles/_material_icon.css";
@import "assets/styles/scrollbar";
@import "assets/styles/dotflashing";
@import "assets/styles/colors";
@import "@ctrl/ngx-emoji-mart/picker";
@import "assets/styles/mat-button";
//@import "assets/styles/compressed-material";

.mat-loading {
  &.mat-mdc-icon-button,
  .mat-button-wrapper {
    display: none;
  }

  .mat-mdc-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }

  .mat-icon,
  .mdc-button__label {
    display: none !important;
  }
}

.logo-toolbar {
  height: 40%;
  margin-right: 5px;
}

.icon-toolbar {
  margin-right: 10px;
}

mat-sidenav-content {
  background-color: white;
}

.btn-save {
  width: 150px;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 20px !important;
}

mat-cell {
  button:not(:last-child) {
    margin-right: 5px;
  }
}

.mat-toolbar-row {
  .mat-button-wrapper {
    span {
      margin-left: 5px;
    }
  }
}

.table-container {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  @media screen and (max-width: 992px) {
    .mat-table-sticky-border-elem-right {
      box-shadow:
        -2px 0 0 0 rgba(0, 0, 0, 0.04),
        inset 1px 0 0 0 rgba(0, 0, 0, 0.12);
    }

    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
      padding: 0 5px;
    }
  }

  .mat-cell-no-row {
    text-align: center;
    opacity: 0.8;
  }
}

.fix-prefix.mat-form-field-appearance-outline {
  .mat-form-field-prefix {
    top: 0 !important;

    span {
      padding: 0 0.5rem;
      opacity: 0.8;
      font-weight: 500;
    }
  }
}

.fix-suffix.mat-form-field-appearance-outline {
  .mat-form-field-suffix {
    top: 0 !important;

    span {
      padding: 0 0.5rem;
      opacity: 0.8;
      font-weight: 500;
    }
  }
}

.right-sheet-container {
  background-color: white;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;

  .fix-width {
    @media screen and (max-width: 399px) {
      max-width: 300px !important;
    }

    @media screen and (min-width: 400px) and (max-width: 499px) {
      max-width: 350px !important;
    }
    @media screen and (min-width: 500px) {
      th.mat-header-cell,
      td.mat-cell,
      td.mat-footer-cell {
        padding: 0 5px;
      }
    }
  }
}

.empty-cell {
  opacity: 0.7;
}

.mat-checkbox-layout {
  white-space: unset !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
}

/** Dialog Content **/
.dialog-content {
  overflow: unset !important;

  &.width-500 {
    width: 500px !important;
  }

  &.width-600 {
    width: 600px !important;
  }

  mat-radio-button {
    p,
    span {
      white-space: initial !important;
    }

    p {
      margin: 0;
    }

    mat-hint {
      font-size: 13px;
      white-space: initial;
    }
  }
}

@media screen and (max-width: 600px) {
  .dialog-content {
    overflow: unset !important;

    &.width-500,
    &.width-600 {
      max-width: 100% !important;
    }
  }
}

.notifications-panel {
  position: absolute !important;
  top: 50px;
  right: 100px;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 15px !important;
  }

  @media screen and (max-width: 450px) {
    right: unset !important;
  }

  .mat-mdc-dialog-content {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-mdc-dialog-title {
    padding: 0 !important;

    &::before {
      margin: 0 !important;
      content: unset !important;
    }
  }
}

/** CSS per la visualizzazione delle icone di stato */
.status-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 0.7rem;
  width: 0.7rem;
  border: 1px solid white;
  border-radius: 50%;
}

.status-icon.assente {
  background-color: #d9d9d9;
}

.status-icon.remoto {
  background-color: rgb(245 158 11);
}

.status-icon.presente {
  background-color: #188038;
}

.no-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.extend-form-field {
  .mat-mdc-form-field {
    width: 100%;
  }
}

.mtx-datetimepicker-content .mtx-calendar {
  height: unset !important;
}

.textarea {
  min-height: 150px;
}

.mat-mdc-form-field-subscript-wrapper {
  mat-hint {
    margin-top: -3px;
    color: var(--black);
  }
}

.ghost-element {
  .line-form {
    width: 100%;
    height: 56px;
    margin: 0.25em 0 1.34375em 0;
    border-radius: 3px;
    background: rgb(204, 199, 199) linear-gradient(90deg, #f0f0f0, #d8d6d6, #f0f0f0) fixed 0 0;
    animation: ghost 1200ms infinite linear;
  }

  .flex flex-row {
    .line-form {
      width: 18%;
    }
  }
}

@media (max-width: 1023px) {
  .mat-toolbar-row {
    .mdc-button {
      .mat-icon {
        margin-right: 0 !important;
      }

      .mdc-button__label {
        display: none;
      }
    }
  }

  .hide-label {
    .mat-icon {
      margin-right: 0 !important;
    }

    .mdc-button__label {
      display: none;
    }
  }
}

.flex-10 {
  flex: 1 1 10%;
  min-width: 10%;
  box-sizing: border-box;
}

.flex-15 {
  flex: 1 1 15%;
  min-width: 15%;
  box-sizing: border-box;
}

.flex-30 {
  flex: 1 1 30%;
  min-width: 30%;
  box-sizing: border-box;
}

.flex-55 {
  flex: 1 1 55%;
  min-width: 55%;
  box-sizing: border-box;
}

.flex-80 {
  flex: 1 1 80%;
  min-width: 80%;
  box-sizing: border-box;
}

.divide-y {
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}
