@use "@angular/material" as mat;
@use "@ng-matero/extensions" as mtx;

$md-primary: (
  50: #e6f2f9,
  100: #c0ddf0,
  200: #97c7e7,
  300: #6db1dd,
  400: #4da0d5,
  500: #2e8fce,
  600: #2987c9,
  700: #237cc2,
  800: #1d72bc,
  900: #1260b0,
  A100: #e0eeff,
  A200: #add3ff,
  A400: #7ab7ff,
  A700: #61a9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-accent: (
  50: #fef3e4,
  100: #fce2bd,
  200: #facf91,
  300: #f8bb64,
  400: #f7ad43,
  500: #f59e22,
  600: #f4961e,
  700: #f28c19,
  800: #f08214,
  900: #ee700c,
  A100: #ffffff,
  A200: #ffefe5,
  A400: #ffd1b2,
  A700: #ffc298,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-warn: (
  50: #f9e7e7,
  100: #f1c3c3,
  200: #e89b9b,
  300: #df7373,
  400: #d85555,
  500: #d13737,
  600: #cc3131,
  700: #c62a2a,
  800: #c02323,
  900: #b51616,
  A100: #ffe7e7,
  A200: #ffb4b4,
  A400: #ff8181,
  A700: #ff6868,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Primary theme
$my-primary: mat.m2-define-palette($md-primary);
$my-accent: mat.m2-define-palette($md-accent);
$my-warn: mat.m2-define-palette($md-warn);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    density: -1,
  )
);

// Apply the dark theme by default
@include mat.all-component-themes($my-theme);
@include mtx.all-component-themes($my-theme);

$button-size: 22px;
$button-lay-size: 44px;

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: $button-lay-size !important;
  width: $button-lay-size !important;
}

td,
mat-nested-tree-node {
  mat-icon {
    font-size: $button-size !important;
    width: $button-size !important;
    height: $button-size !important;
    line-height: $button-size;
  }
}

html,
body {
  height: 100%;
}

* {
  letter-spacing: normal !important;
}

body {
  background-color: #f8f8f8;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

b {
  font-weight: 500 !important;
}

$green: #188038;

:root {
  --primary: rgba(61, 147, 206, 0.94);
  --primary-dark: #13527b;
  --success: #188038;
  --success-dark: #105525;
  --accent: #f59e22;
  --accent-dark: #c58508;
  --warn: #d13737;
  --warn-dark: #ad2828;
  --green: #188038;
  --light-gray: #d9d9d9;
  --gray: #a1a1a1;
  --black: #3c4043;
  --disabled-color: rgba(0, 0, 0, 0.9);

  --mdc-outlined-text-field-disabled-label-text-color: var(--disabled-color);
  --mdc-outlined-text-field-disabled-input-text-color: var(--disabled-color);
  --mdc-outlined-text-field-disabled-input: var(--disabled-color);
  --mat-select-disabled-trigger-text-color: var(--disabled-color);
  --mdc-switch-disabled-label-text-color: var(--disabled-color);
}

.mat-mdc-radio-button.mat-accent {
  --mat-radio-disabled-label-color: var(--disabled-color);
}

.mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.55 !important;
}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  --mat-checkbox-disabled-label-color: var(--disabled-color);
}

p {
  a {
    text-decoration: none;
    color: mat.m2-get-color-from-palette($md-primary, 800);
  }
}

// Text

.text-primary {
  color: mat.m2-get-color-from-palette($md-primary, 500) !important;
}

.text-secondary {
  color: mat.m2-get-color-from-palette($md-accent, 500) !important;
}

.text-success {
  color: $green !important;
}

.text-gold {
  color: gold;
}

.text-warning {
  color: mat.m2-get-color-from-palette($md-warn, 500) !important;
}

.text-light-gray {
  color: var(--light-gray);
}

.text-gray {
  color: var(--gray) !important;
}

.text-black {
  color: var(--black);
}

// Background
.background-primary {
  background-color: mat.m2-get-color-from-palette($md-primary, 500) !important;
}

.background-accent {
  background-color: mat.m2-get-color-from-palette($md-accent, 500) !important;
}

.background-warning {
  background-color: mat.m2-get-color-from-palette($md-warn, 500) !important;
}

.background-success {
  background-color: $green !important;
}

// Snackbar
.snackbar-primary {
  background: mat.m2-get-color-from-palette($md-primary, 500) !important;
  color: rgba(255, 255, 255, 1);
}

.snackbar-accent {
  background: mat.m2-get-color-from-palette($md-accent, 500) !important;
  color: rgba(255, 255, 255, 1);
}

.snackbar-accent {
  background: var(--accent) !important;
  color: rgba(255, 255, 255, 1);

  .mdc-snackbar__surface {
    background: unset !important;
  }
}

.snackbar-warning {
  background: var(--warn) !important;
  color: rgba(255, 255, 255, 1) !important;

  .mat-simple-snackbar-action {
    color: antiquewhite;
  }

  .mdc-snackbar__surface {
    background: unset !important;
  }
}

.snackbar-success {
  background: var(--success) !important;
  color: rgba(255, 255, 255, 1);

  .mdc-snackbar__surface {
    background: unset !important;
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  background-color: rgb(25 106 159 / 8%) !important;
}

.mat-chip.mat-standard-chip.mat-chip-warn {
  background-color: mat.m2-get-color-from-palette($md-warn, 500) !important;
  color: white !important;
  fill: white !important;
}

.sidenav-container {
  top: 60px !important;
}

@media (max-width: 599px) {
  .sidenav-container {
    top: 52px !important;
  }
}

/* compressed table */
$compressed-row-height: 30px;
.compressed-table {
  overflow: hidden;

  tr.mat-mdc-row,
  tr.mat-mdc-header-row {
    height: $compressed-row-height !important;
  }

  .mat-icon {
    scale: 0.75;
  }

  .mdc-checkbox {
    padding: unset;
  }

  .mdc-checkbox .mdc-checkbox__background {
    top: unset;
    left: unset;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 30px;
  }

  .mat-mdc-icon-button {
    padding: 3px !important;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: $compressed-row-height !important;
    width: $compressed-row-height !important;
  }

  .mat-mdc-paginator-container {
    min-height: $compressed-row-height !important;
  }

  .mat-mdc-paginator
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .mat-mdc-text-field-wrapper {
    height: 27px;
  }
}
