@mixin MatIconButtonSize($name, $buttonSize, $iconSize) {
  .mat-#{$name}-icon-button {
    width: $buttonSize !important;
    height: $buttonSize !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role="img"] {
      width: $iconSize;
      height: $iconSize;
      font-size: $iconSize;

      svg {
        width: $iconSize;
        height: $iconSize;
      }
    }

    .mat-mdc-button-touch-target {
      width: $buttonSize !important;
      height: $buttonSize !important;
    }
  }
}

@include MatIconButtonSize("large", 40px, 24px);
@include MatIconButtonSize("medium", 32px, 19px);
@include MatIconButtonSize("small", 26px, 16px);
@include MatIconButtonSize("tiny", 20px, 12px);

@mixin MatButtonSize($name, $buttonSize, $iconSize) {
  .mat-#{$name}-button {
    height: $buttonSize !important;
    padding: 0 !important;

    & > *[role="img"] {
      width: $iconSize !important;
      height: $iconSize !important;
      font-size: $iconSize !important;

      svg {
        width: $iconSize;
        height: $iconSize;
      }
    }

    .mat-mdc-button-touch-target {
      width: $buttonSize !important;
      height: $buttonSize !important;
    }

    & > span {
      font-size: calc($iconSize - 5px) !important;
    }
  }
}

@include MatButtonSize("large", 50px, 24px);
@include MatButtonSize("medium", 32px, 19px);
@include MatButtonSize("small", 26px, 16px);
@include MatButtonSize("tiny", 20px, 12px);
